// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.candidates{
    .table{
        th:hover {
            cursor: pointer;
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/candidates/candidates.css"],"names":[],"mappings":"AAAA;IACI;QACI;YACI,eAAe;QACnB;IACJ;AACJ","sourcesContent":[".candidates{\r\n    .table{\r\n        th:hover {\r\n            cursor: pointer;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
