// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard {
    .filter {
        position: absolute;
        right: 0;
        top: 15px;

        .icon {
            color: #aab7cf;
            padding-right: 20px;
            padding-bottom: 5px;
            transition: 0.3s;
            font-size: 16px;

            &:hover, &:focus {
                color: var(--primary);
            }
        }

        .dropdown-header {
            padding: 8px 15px;

            h6 {
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 1px;
                color: #aab7cf;
                margin-bottom: 0;
                padding: 0;
            }
        }

        .dropdown-item {
            padding: 8px 15px;
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/dashboard.css"],"names":[],"mappings":"AAAA;IACI;QACI,kBAAkB;QAClB,QAAQ;QACR,SAAS;;QAET;YACI,cAAc;YACd,mBAAmB;YACnB,mBAAmB;YACnB,gBAAgB;YAChB,eAAe;;YAEf;gBACI,qBAAqB;YACzB;QACJ;;QAEA;YACI,iBAAiB;;YAEjB;gBACI,yBAAyB;gBACzB,eAAe;gBACf,gBAAgB;gBAChB,mBAAmB;gBACnB,cAAc;gBACd,gBAAgB;gBAChB,UAAU;YACd;QACJ;;QAEA;YACI,iBAAiB;QACrB;IACJ;AACJ","sourcesContent":[".dashboard {\r\n    .filter {\r\n        position: absolute;\r\n        right: 0;\r\n        top: 15px;\r\n\r\n        .icon {\r\n            color: #aab7cf;\r\n            padding-right: 20px;\r\n            padding-bottom: 5px;\r\n            transition: 0.3s;\r\n            font-size: 16px;\r\n\r\n            &:hover, &:focus {\r\n                color: var(--primary);\r\n            }\r\n        }\r\n\r\n        .dropdown-header {\r\n            padding: 8px 15px;\r\n\r\n            h6 {\r\n                text-transform: uppercase;\r\n                font-size: 14px;\r\n                font-weight: 600;\r\n                letter-spacing: 1px;\r\n                color: #aab7cf;\r\n                margin-bottom: 0;\r\n                padding: 0;\r\n            }\r\n        }\r\n\r\n        .dropdown-item {\r\n            padding: 8px 15px;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
