import React, {useState, useEffect} from 'react'
import './navavatar.css'
import profileImg from '../../assets/images/noavatar.png'
import {jwtDecode} from 'jwt-decode';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { HOST_URL } from '../../utils/consts';


const Navavatar = observer(() => {

  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Получение данных о пользователе
    const fetchUserData = async () => {
      try {
        const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZW1haWwiOiJsZW9uaWQxNTk1QGdtYWlsLmNvbSIsInJvbGUiOiLQkNC00LzQuNC90LjRgdGC0YDQsNGC0L7RgCIsImlhdCI6MTcyOTY3ODE5NCwiZXhwIjoxNzI5NzY0NTk0fQ.eqF8-stzwkHhTwMUJNQU--kAefB2D_5KDMNp32_k2Y0";
        const {id} = jwtDecode(token);
        if (token) {
          const response = await axios.get(`${HOST_URL}/api/v1/user/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setUser(response.data);
        }
      } catch (error) {
        console.error('Ошибка получения данных пользователя', error);
      }
    };

    fetchUserData();
  }, []);

  const handleLogout = () => {
    
    navigate('/login');
  };

  const first_name = user ? user.first_name : '';
  const last_name = user ? user.last_name : '';
  const initial = last_name ? last_name.charAt(0).toUpperCase() : '';

  if (!user) {
    return null; 
  }

  

  return (
    <li className='nav-item dropdown pe-3'>
      <a className='nav-link nav-profile d-flex align-items-center pe-0' href='/' data-bs-toggle='dropdown'>
        <img src={profileImg} alt='Profile' className='rounded-circle' />
        <span className='d-none d-md-block dropdown-toggle ps-2'>{`${first_name} ${initial}.`}</span>
      </a>

      <ul className='dropdown-menu dropdown-menu-end dropdown-menu-arrow profile'>
        <li className='dropdown-header'>
          <h6>{last_name} {first_name}</h6>
          <span>{user.roles[0].role_name}</span>
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>

        <li>
          <a className='dropdown-item d-flex alighn-item-center' href='/'>
            <i className='bi bi-person'></i>
            <span>Мой Аккаунт</span>
          </a>
        </li>

        <li>
          <hr className="dropdown-divider" />
        </li>

        <li>
          <a className='dropdown-item d-flex alighn-item-center' href='/'>
            <i className='bi bi-gear'></i>
            <span>Настройки</span>
          </a>
        </li>

        <li>
          <hr className="dropdown-divider" />
        </li>

        <li>
          <a className='dropdown-item d-flex alighn-item-center' href='/' onClick={handleLogout}>
            <i className='bi bi-box-arrow-right'></i>
            <span>Выйти</span>
          </a>
        </li>

      </ul>
    </li>
  )
})

export default Navavatar