// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-nav {
    ul {
        list-style: none;
    }

    & > ul {
        margin: 0;
        padding: 0;
    }

    .nav-icon {
        font-size: 22px;
        color: var(--secondaryTextColor);        
        margin-right: 25px;
        position: relative;
    }

    .nav-profile {
        color: var(--secondaryTextColor);

        img {
            max-height: 36px;
        }

        span {
            font-size: 14px;
            font-weight: 600;
        }
    }
}

.badge-number {
    position: absolute;
    inset: -2px -5px auto auto;
    font-weight: normal;
    font-size: 12px;
    padding: 3px 6px;
}`, "",{"version":3,"sources":["webpack://./src/components/navbar/navbar.css"],"names":[],"mappings":"AAAA;IACI;QACI,gBAAgB;IACpB;;IAEA;QACI,SAAS;QACT,UAAU;IACd;;IAEA;QACI,eAAe;QACf,gCAAgC;QAChC,kBAAkB;QAClB,kBAAkB;IACtB;;IAEA;QACI,gCAAgC;;QAEhC;YACI,gBAAgB;QACpB;;QAEA;YACI,eAAe;YACf,gBAAgB;QACpB;IACJ;AACJ;;AAEA;IACI,kBAAkB;IAClB,0BAA0B;IAC1B,mBAAmB;IACnB,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".header-nav {\r\n    ul {\r\n        list-style: none;\r\n    }\r\n\r\n    & > ul {\r\n        margin: 0;\r\n        padding: 0;\r\n    }\r\n\r\n    .nav-icon {\r\n        font-size: 22px;\r\n        color: var(--secondaryTextColor);        \r\n        margin-right: 25px;\r\n        position: relative;\r\n    }\r\n\r\n    .nav-profile {\r\n        color: var(--secondaryTextColor);\r\n\r\n        img {\r\n            max-height: 36px;\r\n        }\r\n\r\n        span {\r\n            font-size: 14px;\r\n            font-weight: 600;\r\n        }\r\n    }\r\n}\r\n\r\n.badge-number {\r\n    position: absolute;\r\n    inset: -2px -5px auto auto;\r\n    font-weight: normal;\r\n    font-size: 12px;\r\n    padding: 3px 6px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
