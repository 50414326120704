// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile {
    min-width: 240px;
    padding-bottom: 0;
    top: 8px !important;

    .dropdown-header {
        h6 {
            font-size: 18px;
            margin-bottom: 0;
            font-weight: 600;
            color: var(--textColor);
        }
        
        span {
            font-size: 14px;
        }
    }

    .dropdown-item {
        font-size: 14px;
        padding: 10px 15px;
        transition: 0.3s;

        i {
            margin-right: 10px;
            font-size: 18px;
            line-height: 0;
        }

        &:hover {
            background-color: var(--bgColor);
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/components/navavatar/navavatar.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;;IAEnB;QACI;YACI,eAAe;YACf,gBAAgB;YAChB,gBAAgB;YAChB,uBAAuB;QAC3B;;QAEA;YACI,eAAe;QACnB;IACJ;;IAEA;QACI,eAAe;QACf,kBAAkB;QAClB,gBAAgB;;QAEhB;YACI,kBAAkB;YAClB,eAAe;YACf,cAAc;QAClB;;QAEA;YACI,gCAAgC;QACpC;IACJ;AACJ","sourcesContent":[".profile {\r\n    min-width: 240px;\r\n    padding-bottom: 0;\r\n    top: 8px !important;\r\n\r\n    .dropdown-header {\r\n        h6 {\r\n            font-size: 18px;\r\n            margin-bottom: 0;\r\n            font-weight: 600;\r\n            color: var(--textColor);\r\n        }\r\n        \r\n        span {\r\n            font-size: 14px;\r\n        }\r\n    }\r\n\r\n    .dropdown-item {\r\n        font-size: 14px;\r\n        padding: 10px 15px;\r\n        transition: 0.3s;\r\n\r\n        i {\r\n            margin-right: 10px;\r\n            font-size: 18px;\r\n            line-height: 0;\r\n        }\r\n\r\n        &:hover {\r\n            background-color: var(--bgColor);\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
