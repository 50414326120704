import React from 'react'
import './navbar.css'

import Navavatar from '../navavatar/Navavatar'

function Navbar() {
  return (
    <nav className='header-nav ms-auto'>
        <ul className="d-flex align-items-center">            
            <Navavatar />
        </ul>
    </nav>
  )
}

export default Navbar