// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    padding: 20px 0;
    font-size: 14px;
    transition: all 0.3s;
    border-top: 1px solid #cddfff;

    .copyright {
        text-align: center;
        color: #012970;
    }

    .credits {
        padding-top: 5px;
        text-align: center;
        font-size: 13px;
        color: #012970;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,eAAe;IACf,oBAAoB;IACpB,6BAA6B;;IAE7B;QACI,kBAAkB;QAClB,cAAc;IAClB;;IAEA;QACI,gBAAgB;QAChB,kBAAkB;QAClB,eAAe;QACf,cAAc;IAClB;AACJ","sourcesContent":[".footer {\r\n    padding: 20px 0;\r\n    font-size: 14px;\r\n    transition: all 0.3s;\r\n    border-top: 1px solid #cddfff;\r\n\r\n    .copyright {\r\n        text-align: center;\r\n        color: #012970;\r\n    }\r\n\r\n    .credits {\r\n        padding-top: 5px;\r\n        text-align: center;\r\n        font-size: 13px;\r\n        color: #012970;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
