export const HOST_URL = 'http://192.168.0.103:3030'

export const LOGIN_URL = HOST_URL + '/api/v1/user/login'
export const REGISTER_URL = HOST_URL + '/api/v1/user/register'
export const CHECK_URL = HOST_URL + '/api/v1/user/auth'
export const PRODUCT_PAGE = HOST_URL + '/api/v1/product'
export const TEST_PRODUCT_PAGE = HOST_URL + '/api/v1/product/test'
export const CLIENT_PAGE = HOST_URL + '/api/v1/client'
export const ORDER_PAGE = HOST_URL + '/api/v1/order'
export const DASHBOARD_PAGE = HOST_URL + '/api/v1/dashboard'
export const WAREHOUSE_PAGE = HOST_URL + '/api/v1/warehouse'
export const WAREHOUSE_STOCK_PAGE = HOST_URL + '/api/v1/warehouseProduct'

export const COMPARE_PAGE = HOST_URL + '/api/v1/warehouseProduct'


export const OZON_PAGE = HOST_URL + '/api/v1/plugin/Ozon'
export const OZON_PRODUCTS_PAGE = HOST_URL + '/api/v1/marketplace/ozon/products'
