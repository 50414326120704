import React, { useState } from 'react';

function CreateVacancy() {
  const [formData, setFormData] = useState({
    city: '',
    district: '',
    department: '',
    type: 'Стационар',
    workingHours: '',
    payment: ''
  });

  const [routes, setRoutes] = useState([]);
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleRouteChange = (index, e) => {
    const { name, value } = e.target;
    
    // Update the selected route
    const updatedRoutes = routes.map((route, i) => {
      if (i === index) {
        const updatedRoute = { ...route, [name]: value };
        
        // Automatically update total based on ratePerHour and hoursPerWeek
        if (updatedRoute.ratePerHour && updatedRoute.hoursPerWeek) {
          updatedRoute.total = updatedRoute.ratePerHour * updatedRoute.hoursPerWeek;
        }
  
        return updatedRoute;
      }
      return route;
    });
    
    setRoutes(updatedRoutes);
  };

  const addRoute = () => {
    setRoutes([
      ...routes,
      {
        shopId: '',
        shopName: '',
        city: '',
        address: '',
        hoursPerWeek: '',
        visitsPerWeek: '',
        ratePerHour: '',
        total: '',
        comments: ''
      }
    ]);
  };

  const removeRoute = (index) => {
    const updatedRoutes = routes.filter((_, i) => i !== index);
    setRoutes(updatedRoutes);
  };

  const validateForm = () => {
    if (!formData.city || !formData.district || !formData.department || 
        !formData.workingHours || !formData.payment) {
      setError('Все поля вакансии должны быть заполнены');
      return false;
    }

    for (let route of routes) {
      if (!route.shopId || !route.shopName || !route.city || !route.address ||
          !route.hoursPerWeek || !route.visitsPerWeek || !route.ratePerHour) {
        setError('Все поля в маршрутах должны быть заполнены');
        return false;
      }
    }

    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setError('');
      console.log('Вакансия создана', formData, routes);
      // Дальнейшая логика создания вакансии
    }
  };

  return (
    <div className="mt-4 justify-content-between">
      <div className="vacancy-details">
        <h2>Детали вакансии</h2>
        <div className="card">
          <div className="card-body">
            {error && <div className="alert alert-danger">{error}</div>}
            <div className="mb-3">
              <label><strong>Город:</strong></label>
              <input 
                type="text" 
                name="city" 
                className="form-control" 
                value={formData.city} 
                onChange={handleInputChange} 
              />
            </div>
            <div className="mb-3">
              <label><strong>Район:</strong></label>
              <input 
                type="text" 
                name="district" 
                className="form-control" 
                value={formData.district} 
                onChange={handleInputChange} 
              />
            </div>
            <div className="mb-3">
              <label><strong>Отдел:</strong></label>
              <input 
                type="text" 
                name="department" 
                className="form-control" 
                value={formData.department} 
                onChange={handleInputChange} 
              />
            </div>
            <div className="mb-3">
              <label><strong>Тип (Стационар / Визитный):</strong></label>
              <select 
                name="type" 
                className="form-select" 
                value={formData.type} 
                onChange={handleInputChange}
              >
                <option value="Стационар">Стационар</option>
                <option value="Визитный">Визитный</option>
              </select>
            </div>
            <div className="mb-3">
              <label><strong>Часы работы в неделю:</strong></label>
              <input 
                type="number" 
                name="workingHours" 
                className="form-control" 
                value={formData.workingHours} 
                onChange={handleInputChange} 
              />
            </div>
            <div className="mb-3">
              <label><strong>Оплата за 4/5 недели:</strong></label>
              <input 
                type="text" 
                name="payment" 
                className="form-control" 
                value={formData.payment} 
                onChange={handleInputChange} 
              />
            </div>
            <div className="mb-3">
              <label><strong>Комментарии:</strong></label>
              <input 
                type="text" 
                name="comments" 
                className="form-control" 
                value={formData.comments} 
                onChange={handleInputChange} 
              />
            </div>
          </div>
        </div>
      </div>

      <h3 className="mt-4">Маршрут вакантной территории</h3>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>4KAM SHOP ID</th>
            <th>Магазин, вывеска</th>
            <th>Город</th>
            <th>Ул, дом</th>
            <th>Кол-во часов в неделю</th>
            <th>Кол-во визитов в неделю</th>
            <th>Ставка за час</th>
            <th>Итого</th>
            <th>Комментарии</th>
            <th>Удалить</th>
          </tr>
        </thead>
        <tbody>
          {routes.map((route, index) => (
            <tr key={index}>
              <td>
                <input 
                  type="text" 
                  name="shopId" 
                  className="form-control" 
                  value={route.shopId} 
                  onChange={(e) => handleRouteChange(index, e)} 
                />
              </td>
              <td>
                <input 
                  type="text" 
                  name="shopName" 
                  className="form-control" 
                  value={route.shopName} 
                  onChange={(e) => handleRouteChange(index, e)} 
                />
              </td>
              <td>
                <input 
                  type="text" 
                  name="city" 
                  className="form-control" 
                  value={route.city} 
                  onChange={(e) => handleRouteChange(index, e)} 
                />
              </td>
              <td>
                <input 
                  type="text" 
                  name="address" 
                  className="form-control" 
                  value={route.address} 
                  onChange={(e) => handleRouteChange(index, e)} 
                />
              </td>
              <td>
                <input 
                  type="number" 
                  name="hoursPerWeek" 
                  className="form-control" 
                  value={route.hoursPerWeek} 
                  onChange={(e) => handleRouteChange(index, e)} 
                />
              </td>
              <td>
                <input 
                  type="number" 
                  name="visitsPerWeek" 
                  className="form-control" 
                  value={route.visitsPerWeek} 
                  onChange={(e) => handleRouteChange(index, e)} 
                />
              </td>
              <td>
                <input 
                  type="number" 
                  name="ratePerHour" 
                  className="form-control" 
                  value={route.ratePerHour} 
                  onChange={(e) => handleRouteChange(index, e)} 
                />
              </td>
              <td>
                <input 
                    type="number" 
                    name="total" 
                    className="form-control" 
                    value={route.total || 0} 
                    disabled                  
                />
              </td>
              <td>
                <input 
                  type="text" 
                  name="comments" 
                  className="form-control" 
                  value={route.comments} 
                  onChange={(e) => handleRouteChange(index, e)} 
                />
              </td>
              <td>
                <button 
                  className="btn btn-danger" 
                  onClick={() => removeRoute(index)}
                >
                  Удалить
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button className="btn btn-secondary" onClick={addRoute}>
        Добавить маршрут
      </button>

      <div className="create-vacancy">
        <button 
          className="btn btn-primary mt-4" 
          onClick={handleSubmit}
        >
          Создать вакансию
        </button>
      </div>
    </div>
  );
}

export default CreateVacancy;
