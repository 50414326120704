import React from 'react';
import './main.css';
import PageTitle from '../pagetitle/PageTitle';
import Dashboard from '../../pages/dashboard/Dashboard';
import Vacancies from '../../pages/vacancies/Vacancies';
import Candidates from '../../pages/candidates/Candidates';
import Vacancy from '../../pages/vacancy/Vacancy';
import CreateVacancy from '../../pages/vacancy/createVacancy';
import Candidate from '../../pages/candidate/Candidate';

// Маппинг страниц на соответствующие компоненты
const pageComponents = {
  Dashboard: { title: 'Dashboard', component: <Dashboard /> },
  Vacancies: { title: 'Вакансии', component: <Vacancies /> },
  Vacancy: { title: 'Вакансия', component: <Vacancy /> },
  CreateVacancy: { title: 'Новая Вакансия', component: <CreateVacancy /> },
  Candidates: { title: 'Кандидаты', component: <Candidates /> },
  Candidate: { title: 'Кандидат', component: <Candidate /> },
  
};

function Main({ page }) {
  const PageComponent = pageComponents[page];

  return (
    <main id='main' className='main'>
      {PageComponent && (
        <>
          
          {PageComponent.component}
        </>
      )}
    </main>
  );
}

export default Main;
