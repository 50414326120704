// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Общие стили */

  
  h2, h3 {
    color: #2c3e50;
  }
  
  /* Стили для модального окна */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 400px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
.candidates{
        td:hover {
            cursor: pointer;
        }
    
}  
 `, "",{"version":3,"sources":["webpack://./src/pages/vacancy/vacancy.css"],"names":[],"mappings":"AAAA,gBAAgB;;;EAGd;IACE,cAAc;EAChB;;EAEA,8BAA8B;EAC9B;IACE,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,yCAAyC;IACzC,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;EAClB;;AAEF;QACQ;YACI,eAAe;QACnB;;AAER","sourcesContent":["/* Общие стили */\r\n\r\n  \r\n  h2, h3 {\r\n    color: #2c3e50;\r\n  }\r\n  \r\n  /* Стили для модального окна */\r\n  .modal {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    right: 0;\r\n    bottom: 0;\r\n    background-color: rgba(0, 0, 0, 0.7);\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    z-index: 1000;\r\n  }\r\n  \r\n  .modal-content {\r\n    background-color: #fff;\r\n    border-radius: 8px;\r\n    padding: 20px;\r\n    width: 400px;\r\n    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);\r\n    position: relative;\r\n  }\r\n  \r\n  .modal-buttons {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    margin-top: 20px;\r\n  }\r\n  \r\n.candidates{\r\n        td:hover {\r\n            cursor: pointer;\r\n        }\r\n    \r\n}  \r\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
