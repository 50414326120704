// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.candidate-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
  
  .candidate-title {
    text-align: center;
    color: #333;
    font-size: 24px;
    font-weight: bold;
  }
  
  .card {
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .card-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
  }
  
  .form-group input,
  .form-group select,
  .form-group textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .form-group input:focus,
  .form-group select:focus,
  .form-group textarea:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .form-group textarea {
    resize: vertical;
  }
  
  .loading {
    text-align: center;
    font-size: 18px;
    color: #777;
    margin-top: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/candidate/candidate.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;;EAEf;IACE,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;IACzB,kBAAkB;IAClB,wCAAwC;IACxC,aAAa;EACf;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;EACb;;EAEA;;;IAGE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;;;IAGE,qBAAqB;IACrB,aAAa;EACf;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,gBAAgB;EAClB;AACF","sourcesContent":[".candidate-container {\r\n    max-width: 600px;\r\n    margin: 20px auto;\r\n    padding: 20px;\r\n  \r\n  .candidate-title {\r\n    text-align: center;\r\n    color: #333;\r\n    font-size: 24px;\r\n    font-weight: bold;\r\n  }\r\n  \r\n  .card {\r\n    background-color: #f9f9f9;\r\n    border-radius: 8px;\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n    padding: 20px;\r\n  }\r\n  \r\n  .card-body {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 20px;\r\n  }\r\n  \r\n  .form-group {\r\n    display: flex;\r\n    flex-direction: column;\r\n  }\r\n  \r\n  .form-group label {\r\n    font-weight: bold;\r\n    margin-bottom: 5px;\r\n    color: #555;\r\n  }\r\n  \r\n  .form-group input,\r\n  .form-group select,\r\n  .form-group textarea {\r\n    padding: 10px;\r\n    border: 1px solid #ccc;\r\n    border-radius: 4px;\r\n    font-size: 16px;\r\n  }\r\n  \r\n  .form-group input:focus,\r\n  .form-group select:focus,\r\n  .form-group textarea:focus {\r\n    border-color: #007bff;\r\n    outline: none;\r\n  }\r\n  \r\n  .form-group textarea {\r\n    resize: vertical;\r\n  }\r\n  \r\n  .loading {\r\n    text-align: center;\r\n    font-size: 18px;\r\n    color: #777;\r\n    margin-top: 20px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
