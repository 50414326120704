import React from 'react'
import './pagetitle.css'

function PageTitle({page}) {
  return (
    <div className="pagetitle">
        <h1>{page}</h1>        
    </div>
  )
}

export default PageTitle