import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './vacancy.css'

// Mock data
const vacancyData = [
  { _id: '1', route: 1, city: 'Москва', district: 'Центральный', address: 'ул. Тверская, 12, Москва, Россия, 125009', department: 'Продажи', type: 'Стационар', chain: 'Пятерочка', workingHours: 40, payment: 50000, supervisor: 'Иванов И.И.' },
  { _id: '2', route: 2, city: 'Санкт-Петербург', district: 'Василеостровский', address: 'пр. КИМа, 3, Санкт-Петербург, Россия, 199155', department: 'Продажи', type: 'Визитный', chain: 'Магнит', workingHours: 30, payment: 40000, supervisor: 'Петров П.П.' },
  { _id: '3', route: 3, city: 'Москва', district: 'Южный', address: 'ул. Ленинская, 5, Москва, Россия, 125056', department: 'Маркетинг', type: 'Стационар', chain: 'Магнит', workingHours: 35, payment: 45000, supervisor: 'Сидоров С.С.' },
  { _id: '4', route: 4, city: 'Санкт-Петербург', district: 'Петроградский', address: 'ул. Бакунина, 17, Санкт-Петербург, Россия, 194044', department: 'Продажи', type: 'Визитный', chain: 'Пятерочка', workingHours: 38, payment: 48000, supervisor: 'Кузнецов А.А.' },
  { _id: '5', route: 5, city: 'Москва', district: 'Западный', address: 'пр. Ломоносова, 34, Москва, Россия, 123456', department: 'Продажи', type: 'Стационар', chain: 'Перекресток', workingHours: 42, payment: 52000, supervisor: 'Новиков Н.Н.' },
  { _id: '6', route: 6, city: 'Санкт-Петербург', district: 'Невский', address: 'ул. Заневская, 2, Санкт-Петербург, Россия, 195112', department: 'Продажи', type: 'Визитный', chain: 'Магнит', workingHours: 32, payment: 42000, supervisor: 'Васильев В.В.' },
  { _id: '7', route: 7, city: 'Москва', district: 'Северный', address: 'ул. Бутырская, 8, Москва, Россия, 127015', department: 'Маркетинг', type: 'Стационар', chain: 'Пятерочка', workingHours: 40, payment: 51000, supervisor: 'Павлов П.П.' },
  { _id: '8', route: 8, city: 'Санкт-Петербург', district: 'Фрунзенский', address: 'ул. Марата, 12, Санкт-Петербург, Россия, 196105', department: 'Продажи', type: 'Визитный', chain: 'Перекресток', workingHours: 34, payment: 47000, supervisor: 'Горшков Г.Г.' },
  { _id: '9', route: 9, city: 'Москва', district: 'Восточный', address: 'ул. Бауманская, 21, Москва, Россия, 105064', department: 'Продажи', type: 'Стационар', chain: 'Окей', workingHours: 36, payment: 46000, supervisor: 'Романов Р.Р.' },
  { _id: '10', route: 10, city: 'Санкт-Петербург', district: 'Центральный', address: 'ул. Рубинштейна, 10, Санкт-Петербург, Россия, 191002', department: 'Продажи', type: 'Визитный', chain: 'Магнит', workingHours: 28, payment: 39000, supervisor: 'Федоров Ф.Ф.' },
  { _id: '11', route: 11, city: 'Москва', district: 'Юго-Западный', address: 'ул. Новаторов, 16, Москва, Россия, 117418', department: 'Маркетинг', type: 'Стационар', chain: 'Лента', workingHours: 38, payment: 43000, supervisor: 'Козлов К.К.' },
  { _id: '12', route: 12, city: 'Санкт-Петербург', district: 'Красносельский', address: 'ул. Дачная, 9, Санкт-Петербург, Россия, 198330', department: 'Продажи', type: 'Визитный', chain: 'Окей', workingHours: 30, payment: 41000, supervisor: 'Быков Б.Б.' },
  { _id: '13', route: 13, city: 'Москва', district: 'Северо-Восточный', address: 'ул. Академическая, 3, Москва, Россия, 117036', department: 'Продажи', type: 'Стационар', chain: 'Перекресток', workingHours: 44, payment: 54000, supervisor: 'Дмитриев Д.Д.' },
  { _id: '14', route: 14, city: 'Санкт-Петербург', district: 'Адмиралтейский', address: 'ул. Садовая, 27, Санкт-Петербург, Россия, 190068', department: 'Продажи', type: 'Визитный', chain: 'Магнит', workingHours: 40, payment: 50000, supervisor: 'Михайлов М.М.' },
  { _id: '15', route: 15, city: 'Москва', district: 'Центральный', address: 'ул. Арбат, 15, Москва, Россия, 119002', department: 'Продажи', type: 'Стационар', chain: 'Пятерочка', workingHours: 37, payment: 49000, supervisor: 'Егоров Е.Е.' },
  { _id: '16', route: 16, city: 'Санкт-Петербург', district: 'Московский', address: 'ул. Московская, 5, Санкт-Петербург, Россия, 196084', department: 'Продажи', type: 'Визитный', chain: 'Перекресток', workingHours: 29, payment: 41000, supervisor: 'Власов В.В.' },
  { _id: '17', route: 17, city: 'Москва', district: 'Южный', address: 'ул. Академика Миллионщикова, 22, Москва, Россия, 117149', department: 'Маркетинг', type: 'Стационар', chain: 'Окей', workingHours: 36, payment: 47000, supervisor: 'Андреев А.А.' },
  { _id: '18', route: 18, city: 'Санкт-Петербург', district: 'Невский', address: 'ул. Дыбенко, 15, Санкт-Петербург, Россия, 193230', department: 'Продажи', type: 'Визитный', chain: 'Магнит', workingHours: 32, payment: 42000, supervisor: 'Орлов О.О.' },
  { _id: '19', route: 19, city: 'Москва', district: 'Юго-Восточный', address: 'ул. Люблинская, 10, Москва, Россия, 109382', department: 'Продажи', type: 'Стационар', chain: 'Пятерочка', workingHours: 35, payment: 46000, supervisor: 'Смирнов С.С.' },
  { _id: '20', route: 20, city: 'Санкт-Петербург', district: 'Фрунзенский', address: 'ул. Бухарестская, 14, Санкт-Петербург, Россия, 192102', department: 'Продажи', type: 'Визитный', chain: 'Окей', workingHours: 33, payment: 43000, supervisor: 'Тихонов Т.Т.' },
  { _id: '21', route: 21, city: 'Москва', district: 'Западный', address: 'ул. Вернадского, 37, Москва, Россия, 119333', department: 'Маркетинг', type: 'Стационар', chain: 'Лента', workingHours: 40, payment: 51000, supervisor: 'Лебедев Л.Л.' },
  { _id: '22', route: 22, city: 'Санкт-Петербург', district: 'Центральный', address: 'ул. Литейная, 11, Санкт-Петербург, Россия, 191119', department: 'Продажи', type: 'Визитный', chain: 'Магнит', workingHours: 31, payment: 40000, supervisor: 'Крылов К.К.' },
  { _id: '23', route: 23, city: 'Москва', district: 'Северный', address: 'ул. Петровка, 14, Москва, Россия, 127051', department: 'Продажи', type: 'Стационар', chain: 'Окей', workingHours: 42, payment: 54000, supervisor: 'Жуков Ж.Ж.' },
  { _id: '24', route: 24, city: 'Санкт-Петербург', district: 'Василеостровский', address: 'ул. Морская, 20, Санкт-Петербург, Россия, 199034', department: 'Продажи', type: 'Визитный', chain: 'Пятерочка', workingHours: 28, payment: 38000, supervisor: 'Чернов Ч.Ч.' },
  { _id: '25', route: 25, city: 'Москва', district: 'Восточный', address: 'ул. Новая Басманная, 9, Москва, Россия, 107078', department: 'Маркетинг', type: 'Стационар', chain: 'Перекресток', workingHours: 38, payment: 45000, supervisor: 'Соловьев С.С.' },
  { _id: '26', route: 26, city: 'Санкт-Петербург', district: 'Фрунзенский', address: 'ул. Белградская, 7, Санкт-Петербург, Россия, 192288', department: 'Продажи', type: 'Визитный', chain: 'Окей', workingHours: 32, payment: 42000, supervisor: 'Мартынов М.М.' },
  { _id: '27', route: 27, city: 'Москва', district: 'Юго-Западный', address: 'ул. Профсоюзная, 29, Москва, Россия, 117420', department: 'Продажи', type: 'Стационар', chain: 'Магнит', workingHours: 37, payment: 48000, supervisor: 'Панков П.П.' },
  { _id: '28', route: 28, city: 'Санкт-Петербург', district: 'Приморский', address: 'ул. Савушкина, 15, Санкт-Петербург, Россия, 197375', department: 'Маркетинг', type: 'Визитный', chain: 'Пятерочка', workingHours: 40, payment: 50000, supervisor: 'Воронов В.В.' },
  { _id: '29', route: 29, city: 'Москва', district: 'Центральный', address: 'ул. Кузнецкий Мост, 4, Москва, Россия, 107031', department: 'Продажи', type: 'Стационар', chain: 'Окей', workingHours: 39, payment: 46000, supervisor: 'Миронов М.М.' },
  { _id: '30', route: 30, city: 'Санкт-Петербург', district: 'Московский', address: 'пр. Московский, 65, Санкт-Петербург, Россия, 196105', department: 'Продажи', type: 'Визитный', chain: 'Перекресток', workingHours: 31, payment: 42000, supervisor: 'Королёв К.К.' },
  { _id: '31', route: 31, city: 'Москва', district: 'Восточный', address: 'ул. Маросейка, 13, Москва, Россия, 101000', department: 'Маркетинг', type: 'Стационар', chain: 'Магнит', workingHours: 35, payment: 44000, supervisor: 'Фомичёв Ф.Ф.' },
  { _id: '32', route: 32, city: 'Санкт-Петербург', district: 'Адмиралтейский', address: 'ул. Графская, 16, Санкт-Петербург, Россия, 190000', department: 'Продажи', type: 'Визитный', chain: 'Пятерочка', workingHours: 32, payment: 41000, supervisor: 'Ильин И.И.' },
  { _id: '33', route: 33, city: 'Москва', district: 'Южный', address: 'ул. Таганская, 7, Москва, Россия, 109147', department: 'Продажи', type: 'Стационар', chain: 'Окей', workingHours: 40, payment: 49000, supervisor: 'Данилов Д.Д.' },
  { _id: '34', route: 34, city: 'Санкт-Петербург', district: 'Красногвардейский', address: 'ул. Новочеркасская, 6, Санкт-Петербург, Россия, 195112', department: 'Продажи', type: 'Визитный', chain: 'Перекресток', workingHours: 33, payment: 42000, supervisor: 'Мещеряков М.М.' },
  { _id: '35', route: 35, city: 'Москва', district: 'Центральный', address: 'ул. Никитский бульвар, 19, Москва, Россия, 119019', department: 'Маркетинг', type: 'Стационар', chain: 'Магнит', workingHours: 38, payment: 47000, supervisor: 'Платонов П.П.' },
  { _id: '36', route: 36, city: 'Санкт-Петербург', district: 'Василеостровский', address: 'ул. Малый пр., 1, Санкт-Петербург, Россия, 199034', department: 'Продажи', type: 'Визитный', chain: 'Окей', workingHours: 36, payment: 46000, supervisor: 'Беляев Б.Б.' },
  { _id: '37', route: 37, city: 'Москва', district: 'Южный', address: 'ул. Нахимовский пр., 10, Москва, Россия, 117105', department: 'Продажи', type: 'Стационар', chain: 'Пятерочка', workingHours: 37, payment: 49000, supervisor: 'Сафонов С.С.' },
  { _id: '38', route: 38, city: 'Санкт-Петербург', district: 'Невский', address: 'ул. Народная, 4, Санкт-Петербург, Россия, 192007', department: 'Продажи', type: 'Визитный', chain: 'Магнит', workingHours: 32, payment: 42000, supervisor: 'Гришин Г.Г.' },
  { _id: '39', route: 39, city: 'Москва', district: 'Западный', address: 'ул. Покровка, 9, Москва, Россия, 101000', department: 'Маркетинг', type: 'Стационар', chain: 'Перекресток', workingHours: 36, payment: 45000, supervisor: 'Карпов К.К.' },
  { _id: '40', route: 40, city: 'Санкт-Петербург', district: 'Центральный', address: 'ул. Лиговский пр., 31, Санкт-Петербург, Россия, 191119', department: 'Продажи', type: 'Визитный', chain: 'Окей', workingHours: 40, payment: 50000, supervisor: 'Токарев Т.Т.' },
  { _id: '41', route: 41, city: 'Москва', district: 'Юго-Западный', address: 'ул. Профсоюзная, 64, Москва, Россия, 117393', department: 'Продажи', type: 'Стационар', chain: 'Пятерочка', workingHours: 42, payment: 51000, supervisor: 'Семенов С.С.' },
  { _id: '42', route: 42, city: 'Санкт-Петербург', district: 'Приморский', address: 'ул. Коломяжский пр., 19, Санкт-Петербург, Россия, 197348', department: 'Продажи', type: 'Визитный', chain: 'Перекресток', workingHours: 33, payment: 43000, supervisor: 'Николаев Н.Н.' },
  { _id: '43', route: 43, city: 'Москва', district: 'Восточный', address: 'ул. Измайловский бульвар, 3, Москва, Россия, 105037', department: 'Маркетинг', type: 'Стационар', chain: 'Магнит', workingHours: 37, payment: 47000, supervisor: 'Гаврилов Г.Г.' },
];

const additionalData = [
  { id: 63739, store: 'Магнит', city: 'Санкт-Петербург', address: 'ул. Лиговский пр., 31, Санкт-Петербург, Россия, 191119', weeklyHours: 22, weeklyVisits: 4, ratePerHour: 24.88, total: 58.36 },
  { id: 203771, store: 'Перекресток', city: 'Санкт-Петербург', address: 'ул. Измайловский бульвар, 3, Москва, Россия, 105037', weeklyHours: 2, weeklyVisits: 1, ratePerHour: 25, total: 53 },

];

const candidateData = [
  { _id: 1, fullname: 'Иванов Иван Иванович', phone: '+79111111111', mk: 'Есть', android: 'Есть', work: 'Есть', status: 'В работе', comments: 'Выход на стажировку в пн' },
  { _id: 2, fullname: 'Петров Петр Петрович', phone: '+79122222222', mk: 'Есть', android: 'Есть', work: 'Есть', status: 'Отказ', comments: 'Отказался от работы, сложно' },
  { _id: 3, fullname: 'Сидоров Сидор Сидорович', phone: '+79133333333', mk: 'Есть', android: 'Есть', work: 'Есть', status: 'Принят', comments: '' },
];

function Vacancy() {
  const { id } = useParams(); // Получаем id из URL
  const [vacancy, setVacancy] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate(); // Хук для программной навигации
  const [newCandidate, setNewCandidate] = useState({
    fullname: '',
    phone: '',
    mk: 'Есть',
    android: 'Есть',
    work: 'Есть',
  });

  const handleRowClick = (id) => {
    navigate(`/candidate/${id}`); // Переход на страницу с определенным ID
  };

  // Получение данных вакансии по id
  useEffect(() => {
    const foundVacancy = vacancyData.find((v) => v._id === id);
    if (foundVacancy) {
      setVacancy(foundVacancy);
    }
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCandidate((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    console.log('Кандидат направлен:', newCandidate);
    setShowModal(false);
    // Reset the form
    setNewCandidate({
      fullname: '',
      phone: '',
      mk: 'Нет',
      android: 'Нет',
      work: 'Нет',
      status: '',
      comments: '',
    });
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const closeModalOnEsc = (e) => {
    if (e.key === 'Escape') {
      setShowModal(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', closeModalOnEsc);
    return () => {
      window.removeEventListener('keydown', closeModalOnEsc);
    };
  }, []);

  const handleOutsideClick = (e) => {
    // Проверяем, было ли нажатие вне модального окна
    if (e.target.className === 'modal') {
      setShowModal(false);
    }
  };

  if (!vacancy) {
    return <div>Загрузка данных вакансии...</div>;
  }

  return (
    <div className="mt-4">
      <h2>Детали вакансии</h2>
      <div className="card">
        <div className="card-body">
          <p><strong>Маршрут:</strong> {vacancy.route}</p>
          <p><strong>Город:</strong> {vacancy.city}</p>
          <p><strong>Район:</strong> {vacancy.district}</p>
          <p><strong>Адрес ТТ:</strong> {vacancy.address}</p>
          <p><strong>Отдел:</strong> {vacancy.department}</p>
          <p><strong>Тип (Стационар / Визитный):</strong> {vacancy.type}</p>
          <p><strong>Торговые сети / Адрес ТТ:</strong> {vacancy.chain}</p>
          <p><strong>Часы работы в неделю:</strong> {vacancy.workingHours}</p>
          <p><strong>Оплата за 4/5 недели:</strong> {vacancy.payment}</p>
          <p><strong>Супервайзер:</strong> {vacancy.supervisor}</p>
        </div>
      </div>
      <h3 className="mt-4">Маршрут вакантной территории</h3>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>4KAM SHOP ID</th>
            <th>Магазин, вывеска</th>
            <th>Город</th>
            <th>Ул, дом</th>
            <th>Кол-во часов в неделю</th>
            <th>Кол-во визитов в неделю</th>
            <th>Ставка за час</th>
            <th>Итого</th>
          </tr>
        </thead>
        <tbody>
          {additionalData.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.store}</td>
              <td>{item.city}</td>
              <td>{item.address}</td>
              <td>{item.weeklyHours}</td>
              <td>{item.weeklyVisits}</td>
              <td>{item.ratePerHour}</td>
              <td>{item.total}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <h3 className="mt-4">Кандидаты</h3>
      <table className="table table-bordered candidates">
        <thead>
          <tr>
            <th>ФИО</th>
            <th>Номер телефона</th>
            <th>Наличие мед. книжки</th>
            <th>Телефон на Android</th>
            <th>Опыт работы</th>
            <th>Статус</th>
            <th>Комментарии</th>
          </tr>
        </thead>
        <tbody>
          {candidateData.map((item) => (
            <tr key={item._id} onClick={() => handleRowClick(item._id)}>
              <td>{item.fullname}</td>
              <td>{item.phone}</td>
              <td>{item.mk}</td>
              <td>{item.android}</td>
              <td>{item.work}</td>
              <td>{item.status}</td>
              <td>{item.comments}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="create-vacancy">
        <button className="btn btn-primary mt-4" onClick={() => setShowModal(true)}>
          Новый Кандидат
        </button>
      </div>

      {/* Modal for New Candidate */}
      {showModal && (
        <div className="modal" onClick={handleOutsideClick}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3>Добавить нового кандидата</h3>
            <label>
              ФИО:
              <input
                type="text"
                name="fullname"
                value={newCandidate.fullname}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Номер телефона:
              <input
                type="text"
                name="phone"
                value={newCandidate.phone}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Наличие мед. книжки:
              <select name="mk" value={newCandidate.mk} onChange={handleInputChange}>
                <option value="Есть">Есть</option>
                <option value="Нет">Нет</option>
              </select>
            </label>
            <label>
              Телефон на Android:
              <select name="android" value={newCandidate.android} onChange={handleInputChange}>
                <option value="Есть">Есть</option>
                <option value="Нет">Нет</option>
              </select>
            </label>
            <label>
              Опыт работы:
              <select name="work" value={newCandidate.work} onChange={handleInputChange}>
                <option value="Есть">Есть</option>
                <option value="Нет">Нет</option>
              </select>
            </label>
            <div className="modal-buttons">
              <button
                className="btn btn-primary"
                onClick={handleSubmit}
                disabled={
                  !newCandidate.fullname ||
                  !newCandidate.phone ||
                  newCandidate.mk === '' ||
                  newCandidate.android === '' ||
                  newCandidate.work === ''
                }
              >
                Добавить
              </button>
              <button className="btn btn-secondary" onClick={handleCancel}>
                Отменить
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Vacancy;
