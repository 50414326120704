// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo{
    line-height: 1;

    img {
        max-height: 40px;
        margin-right: 6px;
    }

    span {
        font-size: 26px;
        font-weight: 700;
        color: var(--secondaryTextColor);
        font-family: 'Nunito', sans-serif;
    }
}

@media (min-width: 1200px) {
    .logo{
        width: 280px;
    }   
}`, "",{"version":3,"sources":["webpack://./src/components/logo/logo.css"],"names":[],"mappings":"AAAA;IACI,cAAc;;IAEd;QACI,gBAAgB;QAChB,iBAAiB;IACrB;;IAEA;QACI,eAAe;QACf,gBAAgB;QAChB,gCAAgC;QAChC,iCAAiC;IACrC;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".logo{\r\n    line-height: 1;\r\n\r\n    img {\r\n        max-height: 40px;\r\n        margin-right: 6px;\r\n    }\r\n\r\n    span {\r\n        font-size: 26px;\r\n        font-weight: 700;\r\n        color: var(--secondaryTextColor);\r\n        font-family: 'Nunito', sans-serif;\r\n    }\r\n}\r\n\r\n@media (min-width: 1200px) {\r\n    .logo{\r\n        width: 280px;\r\n    }   \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
