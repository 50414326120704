import './App.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'remixicon/fonts/remixicon.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import Header from './components/header/Header';
import Sidebar from './components/sidebar/Sidebar';
import Footer from './components/footer/Footer';
import BackToTop from './components/backtotop/Backtotop';
import Main from './components/main/Main';

const Layout = ({ page }) => (
  <>
    <Header />
    <Sidebar />
    <Main page={page} />
    <Footer />
    <BackToTop />
  </>
);

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Layout page="Dashboard" />} />
        <Route path='/vacancies' element={<Layout page="Vacancies" />} />
        <Route path='/vacancy/:id' element={<Layout page="Vacancy" />} />
        <Route path='/create-vacancy' element={<Layout page="CreateVacancy" />} />
        <Route path='/candidates' element={<Layout page="Candidates" />} />
        <Route path='/candidate/:id' element={<Layout page="Candidate" />} />
      </Routes>
    </Router>
  );
}

export default App;
