import React from 'react'
import './footer.css'

function Footer() {
  return (
    <footer className="footer" id='footer'>
        <div className="copyright">
            &copy; Copyright{' '}
            . All Rights Reserved
        </div>
    </footer>
  )
}

export default Footer