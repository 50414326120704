// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    transition: all 0.5s;
    z-index: 997;
    height: 60px;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    background-color: #fff;
    padding-left: 20px;

    .toggle-sidebar-btn {
        font-size: 32px;
        padding-left: 10px;
        cursor: pointer;
        color: var(--secondaryTextColor);
    }
}`, "",{"version":3,"sources":["webpack://./src/components/header/header.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,YAAY;IACZ,YAAY;IACZ,8CAA8C;IAC9C,sBAAsB;IACtB,kBAAkB;;IAElB;QACI,eAAe;QACf,kBAAkB;QAClB,eAAe;QACf,gCAAgC;IACpC;AACJ","sourcesContent":[".header {\r\n    transition: all 0.5s;\r\n    z-index: 997;\r\n    height: 60px;\r\n    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);\r\n    background-color: #fff;\r\n    padding-left: 20px;\r\n\r\n    .toggle-sidebar-btn {\r\n        font-size: 32px;\r\n        padding-left: 10px;\r\n        cursor: pointer;\r\n        color: var(--secondaryTextColor);\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
