// Dashboard.js
import React, { useState } from 'react';
import { Box, Typography, Select, MenuItem } from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import './dashboard.css'


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


function Dashboard() {

  const [filter, setFilter] = useState('day');
  
  const [data, setData] =  useState({
    candidates: 1,
        internship: 0,
        refused: 0,
        accepted: 0
  });

  const handleChangeFilter = (event) => {
    setFilter(event.target.value);
    if(event.target.value === 'day'){
      setData({
        candidates: 1,
        internship: 0,
        refused: 0,
        accepted: 0})
    }
    else if(event.target.value === 'week'){
      setData({
        candidates: 5,
    internship: 1,
    refused: 2,
    accepted: 2
      })
    }
    else{
      setData({
        candidates: 15,
    internship: 7,
    refused: 5,
    accepted: 3
      })
    }
    
    // Здесь можно добавить логику для изменения данных в зависимости от фильтра
  };

  const chartData = {
    labels: ['Всего кандидатов', 'Направлено на стажировка', 'Отказались', 'Приняты'],
    datasets: [
      {
        label: 'Количество',
        data: [data.candidates, data.internship, data.refused, data.accepted],
        backgroundColor: ['#3f51b5', '#ff9800', '#f44336', '#4caf50']
      }
    ]
  };

  return (
    <section className='dashboard section'>
        <div className="row">
            <div className="col-lg-10">
                <div className="row">
                <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Статистика по кандидатам
      </Typography>
      
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="h6" mr={2}>
          Фильтр:
        </Typography>
        <Select value={filter} onChange={handleChangeFilter}>
          <MenuItem value="day">Текущий день</MenuItem>
          <MenuItem value="week">Текущая неделя</MenuItem>
          <MenuItem value="month">Текущий месяц</MenuItem>
        </Select>
      </Box>

      <Bar
        data={chartData}
        options={{
          responsive: true,
          plugins: {
            legend: { display: true, position: 'bottom' },
          }
        }}
      />
    </Box>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Dashboard