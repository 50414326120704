import React from 'react';
import './sidebar.css';
import { menu } from '../../data/data';

function Sidebar() {
  return (
    <aside id='sidebar' className='sidebar'>
      <ul className='sidebar-nav' id='sidebar-nav'>
        {menu.map(item => (
          <li className='nav-item' key={item.id}>
            {
              Array.isArray(item.listItems) && item.listItems.length === 0 ?
              (
                <a className='nav-link' href={item.url} >
                  <i className={item.icon}></i>
                  <span>{item.title}</span>
                </a>
              ) : 
              (
                <div>
                  <a className='nav-link' href={item.url} >
                    <i className={item.icon}></i>
                    <span>{item.title}</span>
                  </a>
                  {
                  item.listItems.map((listItem) => (
                    <li key={listItem.id} className='nav-content'>
                      <a href={listItem.url}>
                      <i className={listItem.icon}></i>
                      <span>{listItem.title}</span>
                    </a>
                    </li>
                  ))}
                </div>
                
              )
            }
          </li>
        ))}
      </ul>
    </aside>
  );
}

export default Sidebar;
