export const menu = [
    {
        id: 1,
        title: 'Главная',
        url: '/',
        icon: 'bi bi-house',
        listItems: []
    },
    {
        id: 2,
        title: 'Вакансии',
        url: '/vacancies',
        icon: 'bi bi-bag',
        listItems: [
            //{
            //    id: 2,
            //    title: 'Отгрузки',
            //    url: '/shippings',
            //}
        ]
    },
    {
        id: 3,
        title: 'Кандидаты',
        url: '/candidates',
        icon: 'bi bi-people',
        listItems: [
            //{
            //    id: 2,
            //    title: 'Отгрузки',
            //    url: '/shippings',
            //}
        ]
    },
]

export const messages = [
    {   
        id: 1,
        name: 'Lorem Ipsum',
        text: 'asdasdasdajakljdh',
        time: '30 min ago',
        img: 'assets/images/noavatar.png'
    },
    {   
        id: 2,
        name: 'Lorem Ipsum',
        text: 'asdasdasdajakljdh',
        time: '31 min ago',
        img: 'assets/images/noavatar.png'
    },
    {   
        id: 3,
        name: 'Lorem Ipsum',
        text: 'asdasdasdajakljdh',
        time: '30 min ago',
        img: 'assets/images/noavatar.png'
    },
]

export const notif = [
    {   
        id: 1,
        name: 'Lorem Ipsum',
        text: 'asdasdasdajakljdh',
        time: '30 min ago',
        icon: 'bi bi-exclamation-circle text-warning'
    },
    {   
        id: 2,
        name: 'Lorem Ipsum',
        text: 'asdasdasdajakljdh',
        time: '31 min ago',
        icon: 'bi bi-x-circle text-danger'
    },
    {   
        id: 3,
        name: 'Lorem Ipsum',
        text: 'asdasdasdajakljdh',
        time: '30 min ago',
        icon: 'bi bi-check-circle text-success'
    },
    {   
        id: 4,
        name: 'Lorem Ipsum',
        text: 'asdasdasdajakljdh',
        time: '30 min ago',
        icon: 'bi bi-info-circle text-primary'
    }
]

