import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './candidate.css';

const candidateData = [
  { _id: 1, fullname: 'Иванов Иван Иванович', phone: '+79111111111', mk: 'Есть', android: 'Есть', work: 'Есть', status: 'В работе', comments: 'Выход на стажировку в пн' },
  { _id: 2, fullname: 'Петров Петр Петрович', phone: '+79122222222', mk: 'Есть', android: 'Есть', work: 'Есть', status: 'Отказ', comments: 'Отказался от работы, сложно' },
  { _id: 3, fullname: 'Сидоров Сидор Сидорович', phone: '+79133333333', mk: 'Есть', android: 'Есть', work: 'Есть', status: 'Принят', comments: '' },
];

function Candidate() {
  const { id } = useParams();
  const [candidate, setCandidate] = useState(null);

  useEffect(() => {
    const foundCandidate = candidateData.find((v) => v._id === Number(id));
    if (foundCandidate) {
      setCandidate(foundCandidate);
    }
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCandidate((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    console.log('Кандидат направлен:', candidate);
  };

  if (!candidate) {
    return <div className="loading">Загрузка данных...</div>;
  }

  return (
    <div className="candidate-container">
      <h2 className="candidate-title">Данные кандидата</h2>
      <div className="card">
        <div className="card-body">
          {/* Основные поля ввода */}
          {[
            { label: "ФИО", name: "fullname", type: "text", value: candidate.fullname },
            { label: "Номер телефона", name: "phone", type: "text", value: candidate.phone }
          ].map((field) => (
            <div className="form-group" key={field.name}>
              <label>{field.label}:</label>
              <input type={field.type} name={field.name} value={field.value} onChange={handleInputChange} required />
            </div>
          ))}

          {/* Поля выбора */}
          {[
            { label: "Наличие мед. книжки", name: "mk", options: ["Есть", "Нет"], value: candidate.mk },
            { label: "Телефон на Android", name: "android", options: ["Есть", "Нет"], value: candidate.android },
            { label: "Опыт работы", name: "work", options: ["Есть", "Нет"], value: candidate.work },
            {
              label: "Статус",
              name: "status",
              options: ["В работе", "Недозвон", "В процессе оформления мед. книжки", "Отказ", "Принят"],
              value: candidate.status
            }
          ].map((selectField) => (
            <div className="form-group" key={selectField.name}>
              <label>{selectField.label}:</label>
              <select name={selectField.name} value={selectField.value} onChange={handleInputChange}>
                {selectField.options.map((option) => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
            </div>
          ))}

          {/* Блок с комментариями */}
          <div className="form-group">
            <label>Комментарии:</label>
            <textarea
              name="comments"
              value={candidate.comments}
              onChange={handleInputChange}
              rows="3"
              placeholder="Добавьте комментарий"
            />
          </div>
        </div>
        <div className="modal-buttons">
              <button
                className="btn btn-primary"
                onClick={handleSubmit}
                disabled={
                  !candidate.fullname ||
                  !candidate.phone ||
                  candidate.mk === '' ||
                  candidate.android === '' ||
                  candidate.work === ''
                }
              >
                Сохранить
              </button>
            </div>
      </div>
    </div>
  );
}

export default Candidate;
