// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagetitle {
    margin-bottom: 10px;

    h1 {
        font-size: 24px;
        margin-bottom: 0;
        font-weight: 600;
        color: #012970;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/pagetitle/pagetitle.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;;IAEnB;QACI,eAAe;QACf,gBAAgB;QAChB,gBAAgB;QAChB,cAAc;IAClB;AACJ","sourcesContent":[".pagetitle {\r\n    margin-bottom: 10px;\r\n\r\n    h1 {\r\n        font-size: 24px;\r\n        margin-bottom: 0;\r\n        font-weight: 600;\r\n        color: #012970;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
