import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './vacancies.css'

function Vacancies() {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate(); // Хук для программной навигации

  // Данные таблицы с уникальными _id
  const data = [
    { _id: '1', route: 1, city: 'Москва', district: 'Центральный', address: 'ул. Тверская, 12, Москва, Россия, 125009', department: 'Продажи', type: 'Стационар', chain: 'Пятерочка', workingHours: 40, payment: 50000, supervisor: 'Иванов И.И.' },
    { _id: '2', route: 2, city: 'Санкт-Петербург', district: 'Василеостровский', address: 'пр. КИМа, 3, Санкт-Петербург, Россия, 199155', department: 'Продажи', type: 'Визитный', chain: 'Магнит', workingHours: 30, payment: 40000, supervisor: 'Петров П.П.' },
    { _id: '3', route: 3, city: 'Москва', district: 'Южный', address: 'ул. Ленинская, 5, Москва, Россия, 125056', department: 'Маркетинг', type: 'Стационар', chain: 'Магнит', workingHours: 35, payment: 45000, supervisor: 'Сидоров С.С.' },
    { _id: '4', route: 4, city: 'Санкт-Петербург', district: 'Петроградский', address: 'ул. Бакунина, 17, Санкт-Петербург, Россия, 194044', department: 'Продажи', type: 'Визитный', chain: 'Пятерочка', workingHours: 38, payment: 48000, supervisor: 'Кузнецов А.А.' },
    { _id: '5', route: 5, city: 'Москва', district: 'Западный', address: 'пр. Ломоносова, 34, Москва, Россия, 123456', department: 'Продажи', type: 'Стационар', chain: 'Перекресток', workingHours: 42, payment: 52000, supervisor: 'Новиков Н.Н.' },
    { _id: '6', route: 6, city: 'Санкт-Петербург', district: 'Невский', address: 'ул. Заневская, 2, Санкт-Петербург, Россия, 195112', department: 'Продажи', type: 'Визитный', chain: 'Магнит', workingHours: 32, payment: 42000, supervisor: 'Васильев В.В.' },
    { _id: '7', route: 7, city: 'Москва', district: 'Северный', address: 'ул. Бутырская, 8, Москва, Россия, 127015', department: 'Маркетинг', type: 'Стационар', chain: 'Пятерочка', workingHours: 40, payment: 51000, supervisor: 'Павлов П.П.' },
    { _id: '8', route: 8, city: 'Санкт-Петербург', district: 'Фрунзенский', address: 'ул. Марата, 12, Санкт-Петербург, Россия, 196105', department: 'Продажи', type: 'Визитный', chain: 'Перекресток', workingHours: 34, payment: 47000, supervisor: 'Горшков Г.Г.' },
    { _id: '9', route: 9, city: 'Москва', district: 'Восточный', address: 'ул. Бауманская, 21, Москва, Россия, 105064', department: 'Продажи', type: 'Стационар', chain: 'Окей', workingHours: 36, payment: 46000, supervisor: 'Романов Р.Р.' },
    { _id: '10', route: 10, city: 'Санкт-Петербург', district: 'Центральный', address: 'ул. Рубинштейна, 10, Санкт-Петербург, Россия, 191002', department: 'Продажи', type: 'Визитный', chain: 'Магнит', workingHours: 28, payment: 39000, supervisor: 'Федоров Ф.Ф.' },
    { _id: '11', route: 11, city: 'Москва', district: 'Юго-Западный', address: 'ул. Новаторов, 16, Москва, Россия, 117418', department: 'Маркетинг', type: 'Стационар', chain: 'Лента', workingHours: 38, payment: 43000, supervisor: 'Козлов К.К.' },
    { _id: '12', route: 12, city: 'Санкт-Петербург', district: 'Красносельский', address: 'ул. Дачная, 9, Санкт-Петербург, Россия, 198330', department: 'Продажи', type: 'Визитный', chain: 'Окей', workingHours: 30, payment: 41000, supervisor: 'Быков Б.Б.' },
    { _id: '13', route: 13, city: 'Москва', district: 'Северо-Восточный', address: 'ул. Академическая, 3, Москва, Россия, 117036', department: 'Продажи', type: 'Стационар', chain: 'Перекресток', workingHours: 44, payment: 54000, supervisor: 'Дмитриев Д.Д.' },
    { _id: '14', route: 14, city: 'Санкт-Петербург', district: 'Адмиралтейский', address: 'ул. Садовая, 27, Санкт-Петербург, Россия, 190068', department: 'Продажи', type: 'Визитный', chain: 'Магнит', workingHours: 40, payment: 50000, supervisor: 'Михайлов М.М.' },
    { _id: '15', route: 15, city: 'Москва', district: 'Центральный', address: 'ул. Арбат, 15, Москва, Россия, 119002', department: 'Продажи', type: 'Стационар', chain: 'Пятерочка', workingHours: 37, payment: 49000, supervisor: 'Егоров Е.Е.' },
    { _id: '16', route: 16, city: 'Санкт-Петербург', district: 'Московский', address: 'ул. Московская, 5, Санкт-Петербург, Россия, 196084', department: 'Продажи', type: 'Визитный', chain: 'Перекресток', workingHours: 29, payment: 41000, supervisor: 'Власов В.В.' },
    { _id: '17', route: 17, city: 'Москва', district: 'Южный', address: 'ул. Академика Миллионщикова, 22, Москва, Россия, 117149', department: 'Маркетинг', type: 'Стационар', chain: 'Окей', workingHours: 36, payment: 47000, supervisor: 'Андреев А.А.' },
    { _id: '18', route: 18, city: 'Санкт-Петербург', district: 'Невский', address: 'ул. Дыбенко, 15, Санкт-Петербург, Россия, 193230', department: 'Продажи', type: 'Визитный', chain: 'Магнит', workingHours: 32, payment: 42000, supervisor: 'Орлов О.О.' },
    { _id: '19', route: 19, city: 'Москва', district: 'Юго-Восточный', address: 'ул. Люблинская, 10, Москва, Россия, 109382', department: 'Продажи', type: 'Стационар', chain: 'Пятерочка', workingHours: 35, payment: 46000, supervisor: 'Смирнов С.С.' },
    { _id: '20', route: 20, city: 'Санкт-Петербург', district: 'Фрунзенский', address: 'ул. Бухарестская, 14, Санкт-Петербург, Россия, 192102', department: 'Продажи', type: 'Визитный', chain: 'Окей', workingHours: 33, payment: 43000, supervisor: 'Тихонов Т.Т.' },
    { _id: '21', route: 21, city: 'Москва', district: 'Западный', address: 'ул. Вернадского, 37, Москва, Россия, 119333', department: 'Маркетинг', type: 'Стационар', chain: 'Лента', workingHours: 40, payment: 51000, supervisor: 'Лебедев Л.Л.' },
    { _id: '22', route: 22, city: 'Санкт-Петербург', district: 'Центральный', address: 'ул. Литейная, 11, Санкт-Петербург, Россия, 191119', department: 'Продажи', type: 'Визитный', chain: 'Магнит', workingHours: 31, payment: 40000, supervisor: 'Крылов К.К.' },
    { _id: '23', route: 23, city: 'Москва', district: 'Северный', address: 'ул. Петровка, 14, Москва, Россия, 127051', department: 'Продажи', type: 'Стационар', chain: 'Окей', workingHours: 42, payment: 54000, supervisor: 'Жуков Ж.Ж.' },
    { _id: '24', route: 24, city: 'Санкт-Петербург', district: 'Василеостровский', address: 'ул. Морская, 20, Санкт-Петербург, Россия, 199034', department: 'Продажи', type: 'Визитный', chain: 'Пятерочка', workingHours: 28, payment: 38000, supervisor: 'Чернов Ч.Ч.' },
    { _id: '25', route: 25, city: 'Москва', district: 'Восточный', address: 'ул. Новая Басманная, 9, Москва, Россия, 107078', department: 'Маркетинг', type: 'Стационар', chain: 'Перекресток', workingHours: 38, payment: 45000, supervisor: 'Соловьев С.С.' },
    { _id: '26', route: 26, city: 'Санкт-Петербург', district: 'Фрунзенский', address: 'ул. Белградская, 7, Санкт-Петербург, Россия, 192288', department: 'Продажи', type: 'Визитный', chain: 'Окей', workingHours: 32, payment: 42000, supervisor: 'Мартынов М.М.' },
    { _id: '27', route: 27, city: 'Москва', district: 'Юго-Западный', address: 'ул. Профсоюзная, 29, Москва, Россия, 117420', department: 'Продажи', type: 'Стационар', chain: 'Магнит', workingHours: 37, payment: 48000, supervisor: 'Панков П.П.' },
    { _id: '28', route: 28, city: 'Санкт-Петербург', district: 'Приморский', address: 'ул. Савушкина, 15, Санкт-Петербург, Россия, 197375', department: 'Маркетинг', type: 'Визитный', chain: 'Пятерочка', workingHours: 40, payment: 50000, supervisor: 'Воронов В.В.' },
    { _id: '29', route: 29, city: 'Москва', district: 'Центральный', address: 'ул. Кузнецкий Мост, 4, Москва, Россия, 107031', department: 'Продажи', type: 'Стационар', chain: 'Окей', workingHours: 39, payment: 46000, supervisor: 'Миронов М.М.' },
    { _id: '30', route: 30, city: 'Санкт-Петербург', district: 'Московский', address: 'пр. Московский, 65, Санкт-Петербург, Россия, 196105', department: 'Продажи', type: 'Визитный', chain: 'Перекресток', workingHours: 31, payment: 42000, supervisor: 'Королёв К.К.' },
    { _id: '31', route: 31, city: 'Москва', district: 'Восточный', address: 'ул. Маросейка, 13, Москва, Россия, 101000', department: 'Маркетинг', type: 'Стационар', chain: 'Магнит', workingHours: 35, payment: 44000, supervisor: 'Фомичёв Ф.Ф.' },
    { _id: '32', route: 32, city: 'Санкт-Петербург', district: 'Адмиралтейский', address: 'ул. Графская, 16, Санкт-Петербург, Россия, 190000', department: 'Продажи', type: 'Визитный', chain: 'Пятерочка', workingHours: 32, payment: 41000, supervisor: 'Ильин И.И.' },
    { _id: '33', route: 33, city: 'Москва', district: 'Южный', address: 'ул. Таганская, 7, Москва, Россия, 109147', department: 'Продажи', type: 'Стационар', chain: 'Окей', workingHours: 40, payment: 49000, supervisor: 'Данилов Д.Д.' },
    { _id: '34', route: 34, city: 'Санкт-Петербург', district: 'Красногвардейский', address: 'ул. Новочеркасская, 6, Санкт-Петербург, Россия, 195112', department: 'Продажи', type: 'Визитный', chain: 'Перекресток', workingHours: 33, payment: 42000, supervisor: 'Мещеряков М.М.' },
    { _id: '35', route: 35, city: 'Москва', district: 'Центральный', address: 'ул. Никитский бульвар, 19, Москва, Россия, 119019', department: 'Маркетинг', type: 'Стационар', chain: 'Магнит', workingHours: 38, payment: 47000, supervisor: 'Платонов П.П.' },
    { _id: '36', route: 36, city: 'Санкт-Петербург', district: 'Василеостровский', address: 'ул. Малый пр., 1, Санкт-Петербург, Россия, 199034', department: 'Продажи', type: 'Визитный', chain: 'Окей', workingHours: 36, payment: 46000, supervisor: 'Беляев Б.Б.' },
    { _id: '37', route: 37, city: 'Москва', district: 'Южный', address: 'ул. Нахимовский пр., 10, Москва, Россия, 117105', department: 'Продажи', type: 'Стационар', chain: 'Пятерочка', workingHours: 37, payment: 49000, supervisor: 'Сафонов С.С.' },
    { _id: '38', route: 38, city: 'Санкт-Петербург', district: 'Невский', address: 'ул. Народная, 4, Санкт-Петербург, Россия, 192007', department: 'Продажи', type: 'Визитный', chain: 'Магнит', workingHours: 32, payment: 42000, supervisor: 'Гришин Г.Г.' },
    { _id: '39', route: 39, city: 'Москва', district: 'Западный', address: 'ул. Покровка, 9, Москва, Россия, 101000', department: 'Маркетинг', type: 'Стационар', chain: 'Перекресток', workingHours: 36, payment: 45000, supervisor: 'Карпов К.К.' },
    { _id: '40', route: 40, city: 'Санкт-Петербург', district: 'Центральный', address: 'ул. Лиговский пр., 31, Санкт-Петербург, Россия, 191119', department: 'Продажи', type: 'Визитный', chain: 'Окей', workingHours: 40, payment: 50000, supervisor: 'Токарев Т.Т.' },
    { _id: '41', route: 41, city: 'Москва', district: 'Юго-Западный', address: 'ул. Профсоюзная, 64, Москва, Россия, 117393', department: 'Продажи', type: 'Стационар', chain: 'Пятерочка', workingHours: 42, payment: 51000, supervisor: 'Семенов С.С.' },
    { _id: '42', route: 42, city: 'Санкт-Петербург', district: 'Приморский', address: 'ул. Коломяжский пр., 19, Санкт-Петербург, Россия, 197348', department: 'Продажи', type: 'Визитный', chain: 'Перекресток', workingHours: 33, payment: 43000, supervisor: 'Николаев Н.Н.' },
    { _id: '43', route: 43, city: 'Москва', district: 'Восточный', address: 'ул. Измайловский бульвар, 3, Москва, Россия, 105037', department: 'Маркетинг', type: 'Стационар', chain: 'Магнит', workingHours: 37, payment: 47000, supervisor: 'Гаврилов Г.Г.' },
  ];

  // Функция для сокращения адреса
  const truncateAddress = (address, maxLength = 100) => {
    if (address.length > maxLength) {
      return address.slice(0, maxLength) + '...';
    }
    return address;
  };

  // Фильтрация данных по запросу поиска
  const filteredData = data.filter((row) =>
    Object.values(row).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  // Функция для сортировки данных
  const sortedData = [...filteredData].sort((a, b) => {
    if (sortConfig.key) {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
    }
    return 0;
  });

  // Обработчик клика для сортировки по столбцам
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Функция для отображения стрелки
  const getArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '↑' : '↓';
    }
    return '';
  };

  // Обработчик изменения поискового запроса
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Обработчик клика по строке, для перенаправления на страницу вакансии
  const handleRowClick = (id) => {
    navigate(`/vacancy/${id}`); // Переход на страницу с определенным ID
  };

  // Обработчик клика по кнопке "Новая вакансия"
  const handleNewVacancy = () => {
    navigate(`/create-vacancy/`);
  };

  return (
    <div className="card vacancies overflow-auto">
      <div className="card-body table-borderless">
        <div className="d-flex justify-content-between align-items-center mb-3">
          {/* Поисковая форма */}
          <input
            type="text"
            className="form-control w-50 me-3"
            placeholder="Поиск..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <button className="btn btn-primary" onClick={handleNewVacancy}>Новая вакансия</button>
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th onClick={() => handleSort('route')}>Маршрут {getArrow('route')}</th>
              <th onClick={() => handleSort('city')}>Город {getArrow('city')}</th>
              <th onClick={() => handleSort('district')}>Район {getArrow('district')}</th>
              <th onClick={() => handleSort('address')}>Адрес ТТ {getArrow('address')}</th>
              <th onClick={() => handleSort('department')}>Отдел {getArrow('department')}</th>
              <th onClick={() => handleSort('type')}>Тип (Стационар / Визитный) {getArrow('type')}</th>
              <th onClick={() => handleSort('chain')}>Торговые сети / Адрес ТТ {getArrow('chain')}</th>
              <th onClick={() => handleSort('workingHours')}>Часы работы в неделю {getArrow('workingHours')}</th>
              <th onClick={() => handleSort('payment')}>Оплата за 4/5 недели {getArrow('payment')}</th>
              <th onClick={() => handleSort('superviser')}>Супервайзер {getArrow('superviser')}</th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((row) => (
              <tr key={row._id} onClick={() => handleRowClick(row._id)}>
                <td>{row.route}</td>
                <td>{row.city}</td>
                <td>{row.district}</td>
                <td>{truncateAddress(row.address)}</td>
                <td>{row.department}</td>
                <td>{row.type}</td>
                <td>{row.chain}</td>
                <td>{row.workingHours}</td>
                <td>{row.payment}</td>
                <td>{row.supervisor}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Vacancies;