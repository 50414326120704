// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    background: #4154f1;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    transition: all 0.4s;

    i {
        font-size: 24px;
        color: #fff;
        line-height: 0;
    }

    &:hover {
        background: #6776f4;
        color: #fff;
    }

    &.active {
        visibility: visible;
        opacity: 1;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/backtotop/backtotop.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,YAAY;IACZ,cAAc;IACd,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,oBAAoB;;IAEpB;QACI,eAAe;QACf,WAAW;QACX,cAAc;IAClB;;IAEA;QACI,mBAAmB;QACnB,WAAW;IACf;;IAEA;QACI,mBAAmB;QACnB,UAAU;IACd;AACJ","sourcesContent":[".back-to-top {\r\n    position: fixed;\r\n    visibility: hidden;\r\n    opacity: 0;\r\n    right: 15px;\r\n    bottom: 15px;\r\n    z-index: 99999;\r\n    background: #4154f1;\r\n    width: 60px;\r\n    height: 60px;\r\n    border-radius: 30px;\r\n    transition: all 0.4s;\r\n\r\n    i {\r\n        font-size: 24px;\r\n        color: #fff;\r\n        line-height: 0;\r\n    }\r\n\r\n    &:hover {\r\n        background: #6776f4;\r\n        color: #fff;\r\n    }\r\n\r\n    &.active {\r\n        visibility: visible;\r\n        opacity: 1;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
