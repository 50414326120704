import React, { useState } from 'react';
import "./candidates.css";
import { useNavigate } from 'react-router-dom';

function Candidates() {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const navigate = useNavigate();

  const handleRowClick = (id) => {
    navigate(`/candidate/${id}`);
  };

  const data = [
    { _id: 1, fullname: 'Иванов Иван Иванович', phone: '+79111111111', mk: 'Есть', android: 'Есть', work: 'Есть', status: 'В работе', comments: 'Выход на стажировку в пн' },
    { _id: 2, fullname: 'Петров Петр Петрович', phone: '+79122222222', mk: 'Есть', android: 'Есть', work: 'Есть', status: 'Отказ', comments: 'Отказался от работы, сложно' },
    { _id: 3, fullname: 'Сидоров Сидор Сидорович', phone: '+79133333333', mk: 'Есть', android: 'Есть', work: 'Есть', status: 'Принят', comments: '' },
  ];

  const truncateAddress = (address, maxLength = 50) => {
    if (address.length > maxLength) {
      return address.slice(0, maxLength) + '...';
    }
    return address;
  };

  const sortedData = [...data].sort((a, b) => {
    if (sortConfig.key) {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
    }
    return 0;
  });

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '↑' : '↓';
    }
    return '';
  };

  return (
    <div className="card candidates overflow-auto">
      <div className="card-body table-borderless">
        <table className="table table-striped">
          <thead>
            <tr>
              <th onClick={() => handleSort('fullname')}>ФИО {getArrow('fullname')}</th>
              <th onClick={() => handleSort('phone')}>Телефон {getArrow('phone')}</th>
              <th onClick={() => handleSort('mk')}>Наличие мед. книжки {getArrow('mk')}</th>
              <th onClick={() => handleSort('android')}>Телефон на Android {getArrow('android')}</th>
              <th onClick={() => handleSort('work')}>Опыт работы {getArrow('work')}</th>
              <th onClick={() => handleSort('status')}>Статус {getArrow('status')}</th>
              <th onClick={() => handleSort('comments')}>Комментарии {getArrow('comments')}</th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((row) => (
              <tr key={row._id} onClick={() => handleRowClick(row._id)}>
                <td>{truncateAddress(row.fullname)}</td>
                <td>{row.phone}</td>
                <td>{row.mk}</td>
                <td>{row.android}</td>
                <td>{row.work}</td>
                <td>{row.status}</td>
                <td>{row.comments}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Candidates;
